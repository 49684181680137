import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import { useContext } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./App.css";
import AppContext from "../Context/AppContext";
import Login from "./Screens/AuthScreens/Login/Login";
import Register from "./Screens/AuthScreens/Register/Register";
import RecoverPassOne from "./Screens/AuthScreens/RecoverPassOne";
import RecoverPassThree from "./Screens/AuthScreens/RecoverPassThree";
import EmployeesScreen from "./Screens/AuthScreens/EmployeesScreen";
import InviteEmployees from "./Screens/AuthScreens/InviteEmployees/InviteEmployees";
import NavBar from "./Partials/Sections/NavBar/NavBar";
import Loader from "./Partials/Elements/Loader/Loader";
import Dashboard from "./Screens/Dashboard/Dashboard";
import SideBar from "./Partials/Sections/SideBar/SideBar";
import SocialMediaScreen from "./Screens/AppSettingsScreens/SocialMediaScreen/SocialMediaScreen";

import HeroSliders from "./Screens/AppSettingsScreens/HeroSliders/HeroSliders";
import AppSettingsScreens from "./Screens/AppSettingsScreens/AppSettingsScreens";
import EventStatusScreens from "./Screens/Events/EventStatusScreens";
import Ads from "./Screens/AppSettingsScreens/Ads/Ads";
import Managers from "./Screens/AuthScreens/Managers/Managers";
import ManagersScreen from "./Screens/AuthScreens/Managers/ManagersScreen";
import RequestToJoin from "./Screens/MainScreens/RequestToJoin/RequestToJoin";


function App() {
  const { employee, isLoading } = useContext(AppContext);

  return (
    <section>
      <BrowserRouter>
        <NavBar />
        <main>
          {employee && <SideBar />}
          <section className="screen-container">
            <Routes>
              <Route
                path="/login"
                element={!employee ? <Login /> : <Navigate to={"/"} />}
              />
              <Route
                path="/register/:token"
                element={!employee ? <Register /> : <Navigate to={"/"} />}
              />
              <Route path="/recover-pass-one" element={<RecoverPassOne />} />
              <Route
                path="/resetManagerPassword/:token"
                element={<RecoverPassThree />}
              />

              <Route
                path="/employeesScreen"
                element={
                  employee ? <EmployeesScreen /> : <Navigate to={"/login"} />
                }
              />

              <Route
                path="/managersScreen"
                element={
                  employee ? <ManagersScreen /> : <Navigate to={"/login"} />
                }
              />

              <Route
                path="/employeeInvites"
                element={
                  employee ? <InviteEmployees /> : <Navigate to={"/login"} />
                }
              />
              <Route
                path="/managers"
                element={employee ? <Managers/> : <Navigate to={"/login"} />}
              />

              <Route
                path="/requestedToJoin"
                element={employee ? <RequestToJoin/> : <Navigate to={"/login"} />}
              />

              <Route
                path="/"
                element={employee ? <Dashboard /> : <Navigate to={"/login"} />}
              />

              {/* events */}
              <Route
                path="/eventsStatusScreen"
                element={
                  employee ? <EventStatusScreens /> : <Navigate to={"/login"} />
                }
              />
              
              {/* appp setting */}
              <Route
                path="/appSettings"
                element={
                  employee ? <AppSettingsScreens /> : <Navigate to={"/login"} />
                }
              />
              <Route
                path="/socialLinks"
                element={
                  employee ? <SocialMediaScreen /> : <Navigate to={"/login"} />
                }
              />
              <Route
                path="/heroSliders"
                element={
                  employee ? <HeroSliders /> : <Navigate to={"/login"} />
                }
              />
              <Route
                path="/ads"
                element={employee ? <Ads /> : <Navigate to={"/login"} />}
              />



            </Routes>



          </section>
        </main>
      </BrowserRouter>
      <ToastContainer />
      {isLoading && <Loader />}
    </section>
  );
}

export default App;
