import React from 'react'
import NavCardList from '../../../Partials/Layouts/NavCardList/NavCardList'
import NavCard from '../../../Partials/Layouts/NavCardList/NavCard/NavCard'
import { RiGroupLine, RiUserShared2Line } from 'react-icons/ri'

function ManagersScreen() {
    return (
        <NavCardList numOfCards={'four'}>
            <NavCard cardName={'Managers'} navCardLink='/managers' ><RiGroupLine /></NavCard>
            {/* <NavCard cardName={'Manager Invites'} navCardLink='/employeeInvites' ><RiUserShared2Line /></NavCard> */}
            <NavCard cardName={'Requested To Join'} navCardLink='/requestedToJoin' ><RiUserShared2Line /></NavCard>
        </NavCardList>
    )
}

export default ManagersScreen