import SmallSideBarItem from "./SmallSideBarItem/SmallSideBarItem";

import {
  RiCalendarEventLine,
  RiDirectionLine,
  RiGroupLine,
  RiHome2Line,
  RiMoneyDollarBoxLine,
  RiOrderPlayLine,
  RiSettings2Line,
  RiStackLine,
} from "react-icons/ri";

import "./SideBar.css";

function SideBar() {
  return (
    <>
      <section className="small_sidebar">
        <div className="container">
          <SmallSideBarItem link="/" dataTip="Home Page" dataFor="dashboard">
            <RiHome2Line />
          </SmallSideBarItem>

          {/* <SmallSideBarItem
            link="/eventsStatusScreen"
            dataTip="Events"
            dataFor="Events"
          >
            <RiCalendarEventLine />
          </SmallSideBarItem> */}


          <SmallSideBarItem
            link="/managersScreen"
            dataTip="Managers"
            dataFor="Managers">
            <RiGroupLine />
          </SmallSideBarItem>

          {/* <SmallSideBarItem link="/countries" dataTip="Locations" dataFor="Locations">
          <RiDirectionLine />
          </SmallSideBarItem> */}

          {/* <SmallSideBarItem
            link="/appSettings"
            dataTip="App Settings"
            dataFor="App Settings"
          >
            <RiSettings2Line />
          </SmallSideBarItem> */}


        </div>
      </section>
    </>
  );
}

export default SideBar;
