import { useEffect, useState } from 'react'
import { RiCloseCircleLine, RiCheckboxCircleLine } from "react-icons/ri";

import { MANAGE_MANAGER_API, PLAYER_REQUEST_API } from '../../../../Utilities/APIs/APIs'

import BackButton from '../../../Partials/Elements/BackButton/BackButton'
import CRUDTable from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable'
import CRUDTableHeader from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader'
import CRUDth from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth'
import CRUDTableRow from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow'
import ShortTextCell from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell'
import CRUDButton from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton'
import Modal from '../../../Partials/Elements/Modal/Modal'
import AcceptReqeust from './AcceptRequest/AcceptReqeust';
import DeleteItem from '../../../Partials/Layouts/DeleteItem/DeleteItem';
import useAxiosInstance from '../../../../Utilities/Hooks/useAxiosInstance';

function RequestToJoin() {

    const [requestedManagers, setRequestedManagers] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const [targetID, setTargetID] = useState(null)
    const [showAcceptSection, setShowAcceptSection] = useState(false)
    const [showDeleteSection, setShowDeleteSection] = useState(false)
    const [toggleFetch, setToggleFetch] = useState(false)

    const axiosInstance = useAxiosInstance()

    useEffect(() => {

        async function fetchAndSetInvitedEmployees() {
            const { data } = await axiosInstance.get(PLAYER_REQUEST_API)
            setRequestedManagers(data)
        }
        fetchAndSetInvitedEmployees()

    }, [axiosInstance, toggleFetch])

    function handleClose() {
        setShowModal(false)
        setShowAcceptSection(false)
        setShowDeleteSection(false)
    }

    function triggerFetch() {
        setToggleFetch(prevState => !prevState)
    }

    return (
        <>
            <div className='crud_holder'>
                <div className="body_header">


                    <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>

                        <BackButton />
                        <h1 className="heading">{`Request to join managers (${requestedManagers?.length > 0 ? requestedManagers?.length : 0} in total)`}</h1>
                    </div>

                </div>
                <CRUDTable>
                    <CRUDTableHeader>
                        <CRUDth th='Name' />
                        <CRUDth th='Email' />
                        <CRUDth th='Mobile' />
                        <CRUDth th='Actions' />
                    </CRUDTableHeader>
                    <tbody>
                        {requestedManagers?.length > 0 && requestedManagers?.map(request => (
                            <CRUDTableRow key={request?._id}>
                                <ShortTextCell text={request?.manager?.name} />
                                <ShortTextCell text={request?.manager?.email} />
                                <ShortTextCell text={request?.manager?.mobile} />

                                <td className="action_button_cell">
                                    <CRUDButton
                                        dataTip='Accept'
                                        dataFor='Accept request?'
                                        handleClick={() => {
                                            setShowModal(true)
                                            setShowAcceptSection(true)
                                            setTargetID(request?._id)
                                        }}>
                                        <RiCheckboxCircleLine />
                                    </CRUDButton>

                                    <CRUDButton
                                        dataTip='Reject'
                                        dataFor='Reject Request'
                                        handleClick={() => {
                                            setShowModal(true)
                                            setShowDeleteSection(true)
                                            setTargetID(request?._id)
                                        }}>
                                        <RiCloseCircleLine />
                                    </CRUDButton>
                                </td>
                            </CRUDTableRow>
                        ))}
                    </tbody>

                </CRUDTable>

            </div>
            {showModal &&
                <Modal
                    handleClose={handleClose}
                    modalHeading={
                        (showAcceptSection && `Accept Request`) ||
                        (showDeleteSection && `Reject Request`)
                    }
                >

                    {

                        showAcceptSection &&
                        <AcceptReqeust
                            setShowAcceptSection={setShowAcceptSection}
                            setShowModal={setShowModal}
                            triggerFetch={triggerFetch}
                            targetID={targetID}
                        />
                    }

                    {
                        showDeleteSection &&
                        <DeleteItem
                            api={MANAGE_MANAGER_API + "deleteRequestToJoin/"}
                            targetID={targetID}
                            setShowDeleteSection={setShowDeleteSection}
                            setShowModal={setShowModal}
                            triggerFetch={triggerFetch}
                        />
                    }

                </Modal>
            }
        </>
    )
}

export default RequestToJoin