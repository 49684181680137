import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { IMAGE_URL } from "../../../../Utilities/APIs/APIs";
import Image from "../../../Partials/Elements/Image/Image";

function ViewManager({ targetID }) {
    const [manager, setManager] = useState(null);

    const axiosInstance = useAxiosInstance();

    useEffect(() => {
        async function fetchAndSetManagerInfo() {
            const { data } = await axiosInstance.get(
                "manageManagers/getSingleManager/" + targetID
            );
            setManager(data);
        }
        fetchAndSetManagerInfo();
    }, [targetID, axiosInstance]);

    return (
        <div className="crud_view_content">
            {manager && (
                <>
                    <h1>Profile Image</h1>
                    <Image imgLink={manager?.dp} imgAlt={"Profile Image"} />

                    <h1>Name</h1>
                    <p>{manager?.name}</p>

                    <h1>Email</h1>
                    <p>{manager.email}</p>

                    <h1>FP Id</h1>
                    <p>{manager.fplId}</p>

                    <h1>Mobile</h1>
                    <p>{manager.mobile}</p>

                    <h1>University</h1>
                    <p>{manager.university?.name}</p>
                </>
            )}
        </div>
    );
}

export default ViewManager;
