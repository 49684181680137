
import { RiDeleteBin2Line } from 'react-icons/ri';

import './ApprovalButton.css';
import CRUDButton from '../CRUDButton';

function RemoveManagerButton({ setShowModal, setShowDeleteSection, targetID, setTargetID, isArchive, setArchive }) {
  return (
    <CRUDButton
      dataTip='Remove'
      dataFor='Remove Manager'
      handleClick={() => {
        setShowModal(true)
        setShowDeleteSection(true)
        setTargetID(targetID)
        setArchive(isArchive)
      }}
      deleteButton
    >
      <RiDeleteBin2Line />


    </CRUDButton>
  )
}

export default RemoveManagerButton