import { useContext, useEffect, useState } from 'react';
import CRUDTable from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable';
import ImageCell from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ImageCell/ImageCell';
import ShortTextCell from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell';
import CRUDTableHeader from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader';
import CRUDth from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth';
import CRUDTableRow from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow';
import Modal from '../../../Partials/Elements/Modal/Modal';
import useAxiosInstance from '../../../../Utilities/Hooks/useAxiosInstance';
import { ALL_MANAGER_API } from '../../../../Utilities/APIs/APIs';

import AppContext from '../../../../Context/AppContext';
import BackButton from '../../../Partials/Elements/BackButton/BackButton';
import ViewButton from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton';
import ViewManager from './ViewManager';
import RemoveManagerButton from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ApprovalButton/ApprovalButton';
import RemoveItem from '../../../Partials/Layouts/RemoveItem/RemoveItem';

function Managers() {
  const [employees, setEmployees] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);


  const { employee } = useContext(AppContext);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetEmployees() {
      const { data } = await axiosInstance.get(
        `${ALL_MANAGER_API}`,
      );
      setEmployees(data);
    }
    fetchAndSetEmployees();
  }, [employee.token, toggleFetch]);

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  function handleClose() {
    setShowModal(false);
    setShowViewSection(false);
  }

  return (
    <>
      <div className='crud_holder'>
        <div className='body_header'>
          <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
            <BackButton />
            <h1 className='heading'>{`Managers (${employees?.length > 0 ? employees?.length : 0
              } in total)`}</h1>
          </div>
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th='Name' />
            <CRUDth th='University' />
            <CRUDth th='Email Address' />
            <CRUDth th='Actions' />
          </CRUDTableHeader>
          <tbody>
            {employees &&
              employees?.map((e) => (
                <CRUDTableRow key={e._id}>
                  <ShortTextCell text={e.name} />
                  <ShortTextCell text={e?.university?.name} />
                  <ShortTextCell text={e.email} />
                  {employee.isCaptain === true && (
                    <td className='action_button_cell'>
                      <ViewButton
                        setShowModal={setShowModal}
                        setShowViewSection={setShowViewSection}
                        targetID={e._id}
                        setTargetID={setTargetID}
                      />
                      {e?.isCaptain === false && (
                        <RemoveManagerButton
                          setShowModal={setShowModal}
                          setShowDeleteSection={setShowDeleteSection}
                          targetID={e?._id}
                          setTargetID={setTargetID}
                        />

                      )}
                    </td>
                  )}

                </CRUDTableRow>
              ))}
          </tbody>
        </CRUDTable>
      </div>
      {showModal && (
        <Modal
          handleClose={handleClose}
          modalHeading={showDeleteSection && `Approve Invite`}
        >
          {showDeleteSection && (

            <RemoveItem
              api={'manageManagers/removeManagerFromUniversity/'}
              targetID={targetID}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              triggerFetch={triggerFetch}
            />

          )}
        </Modal>
      )}
      {showModal && showViewSection && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={showViewSection && `View Manager`}
        >
          <ViewManager targetID={targetID} employee={employee} />
        </Modal>
      )}
    </>
  );
}

export default Managers;
