


import { MANAGE_MANAGER_API } from '../../../../../Utilities/APIs/APIs'
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance'

import "./AcceptRequest.css"

function AcceptReqeust({ targetID, setShowAcceptSection, setShowModal, triggerFetch }) {

    const axiosInstance = useAxiosInstance()

    async function handleDelete(id) {
        const res = await axiosInstance.patch(MANAGE_MANAGER_API + "updateRequestToJoin/" + id, { isAccepted: true })

        if (res.data) {
            setShowModal(false)
            setShowAcceptSection(false)
            triggerFetch()
        }
    }

    return (
        <div className="delete_item">
            <div className="delete_content">
                <h1>Are you sure?</h1>
                <p>Please confirm you want to accept this manager request.</p>
                <div className="btn_container">

                    <button
                        onClick={() => handleDelete(targetID)}
                        className='accept'
                    >Accept
                    </button>

                    <button className='cancel'
                        onClick={() => {
                            setShowModal(false);
                        }}
                    >Cancel</button>
                </div>
            </div>
        </div>
    )
}

export default AcceptReqeust
