import { useEffect, useState } from 'react';
import './Dashboard.css';
import DashboardWrapper from './DashboardWrapper';
import useAxiosInstance from '../../../Utilities/Hooks/useAxiosInstance';
import DashboardCard from './DashboardCard';

function Dashboard() {
  const axiosInstance = useAxiosInstance();
  const [pastEvents, setPastEvents] = useState([]);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [nowSellingEvents, setNowSellingEvents] = useState([]);
  const [voteToBringEvents, setVoteToBringEvents] = useState([]);

  useEffect(() => {
    async function setAndFetchEventsInfo() {
      const { data } = await axiosInstance.get('/getDashboardInfo');
      setPastEvents(data.pastEvents);
      setNowSellingEvents(data.nowSellingEvents);
      setUpcomingEvents(data.upcomingEvents);
      setVoteToBringEvents(data.voteToBringEvents);
    }
    setAndFetchEventsInfo();
  }, [axiosInstance]);
  return (
    <DashboardWrapper>
      {/* <DashboardCard title={"Now Selling Events"} count={nowSellingEvents} />
      <DashboardCard title={"Upcoming Events"} count={upcomingEvents} />
      <DashboardCard title={"Past Events"} count={pastEvents} />
      <DashboardCard title={"Vote To Bring Events"} count={voteToBringEvents} /> */}
    </DashboardWrapper>
  );
}

export default Dashboard;
