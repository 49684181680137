import useAxiosInstance from '../../../../Utilities/Hooks/useAxiosInstance';
import './RemoveItem.css'

function RemoveItem({ api, targetID, setShowDeleteSection, setShowModal, triggerFetch, isApproved }) {

    const axiosInstance = useAxiosInstance()

    async function handleArchive(id) {

        const res = await axiosInstance.patch(api + id, {})

        if (res.data) {
            setShowModal(false)
            setShowDeleteSection(false)
            triggerFetch()
        }
    }

    return (
        <div className="delete_item">
            <div className="delete_content">
                <h1>Are you sure?</h1>
                <p>Please confirm you want to remove this manager.</p>
                <div className="btn_container">

                    <button
                        onClick={() => handleArchive(targetID)}
                        className='delete'
                    >Remove
                    </button>

                    <button className='cancel'
                        onClick={() => {
                            setShowModal(false);
                        }}
                    >Cancel</button>
                </div>
            </div>
        </div>
    )
}

export default RemoveItem 