export const IMAGE_URL = process.env.REACT_APP_SPACES_URL 



export const MANAGE_MANAGER_API = 'manageManagers/'
// employee
export const ALL_MANAGER_API= "manageManagers/getManagersByUniversityForCaptain"

// Invite employess
// export const ALL_EMPLOYEE_INVITES_API = 'employeeInvite/'

export const EMPLOYEE_INVITES_API ='manageManagers/invite/'

// customers
export const CUSTOMER_API = "customers/"

export const ORDERS_API = "orders/"

export const PLAYER_REQUEST_API = 'manageManagers/getRequestedManagersByUniversity'


